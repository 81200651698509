import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { customTextModalShow } from '../../redux/counterSlice';
import { useRouter } from 'next/router';
import 'react-tabs/style/react-tabs.css';
import Meta from '../../components/Meta';
import useFirebaseAuth from '../../lib/useFirebaseAuth';
import userService from '../../services/userService';
import { DatabaseUser } from '../../models/User';
import { useForm } from 'react-hook-form';
import { FlowSteps } from '../../models/FlowSteps';

export default function Login() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [email, setEmail] = useState('');
  const { mySignInWithGooglePopup, mySendSignInLinkToEmail, authUser } = useFirebaseAuth();
  const { createDatabaseUser, updateDatabaseUser, getDatabaseUserByEmail, getDatabaseUser } =
    userService();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleEmailChange = (event) => {
    //remove spaces in email input html
    setEmail(event.target.value.replace(/\s/g, ''));
    // setEmail(event.target.value.trim());
  };

  const handleGoogleLogin = useCallback(async () => {
    // Sign in with Google
    try {
      const authUser = await mySignInWithGooglePopup();
      if (authUser) {
        const databaseUser = await getDatabaseUserByEmail(authUser.user.email);
        if (databaseUser) {
          await updateDatabaseUser(databaseUser.id, { lastLogin: new Date() });
          router.push('/all-plans');
        } else {
          const newUser: DatabaseUser = {
            email: authUser.user.email,
            displayName: authUser.user.displayName,
            photoURL: authUser.user.photoURL,
            id: authUser.user.uid,
            emailVerified: true,
            lastLogin: new Date(),
            createdAt: new Date(),
            step: FlowSteps.Auth,
          };
          await createDatabaseUser(newUser);
          dispatch(
            customTextModalShow([
              '¡Que gusto saludarte ' + authUser.user.displayName + '!',
              'Comienza por seleccionar cómo quieres lucir en tu paquete de fotos',
              'Comenzar',
            ])
          );
          router.push('/styles-selection/');
        }
      }
    } catch (error) {
      dispatch(customTextModalShow(['Ups ocurrió un error', error.message, 'Ok']));
    }
  }, [
    createDatabaseUser,
    dispatch,
    getDatabaseUserByEmail,
    mySignInWithGooglePopup,
    router,
    updateDatabaseUser,
  ]);

  const handleEmailLogin = useCallback(
    async (data) => {
      const { email } = data;

      try {
        console.log(email, 'Valoresss');
        mySendSignInLinkToEmail(email)
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email);
            // router.reload();
            dispatch(
              customTextModalShow([
                '¡Listo!',
                'Te hemos enviado un correo para que puedas iniciar sesión. Recuerda revisar la carpeta de Spam/No deseados.',
                'Ok',
              ])
            );
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
            dispatch(customTextModalShow(['Ups ocurrió un error', errorMessage, 'Ok']));
          });
      } catch (err) {
        console.error(err);
        dispatch(customTextModalShow(['Ups ocurrió un error', err.message, 'Ok']));
      }
    },
    [dispatch, mySendSignInLinkToEmail, router]
  );

  return (
    <div>
      <Meta title='Inicia Sesión | Mi Foto de Perfil' />
      {/* <!-- Login --> */}
      <section className='relative h-screen'>
        <div className='lg:flex lg:h-full'>
          {/* <!-- Left --> */}
          <div className='relative text-center lg:w-1/2'>
            <img
              src='/images/login.png'
              alt='login'
              className='absolute h-full w-full object-cover'
            />
            {/* <!-- Logo --> */}
            <Link href='/' className='relative inline-block py-32 px-4'>
              <img src='/images/logo_white.png' className='inline-block ' alt='Mi Foto de Perfil' />
            </Link>
          </div>

          {/* <!-- Right --> */}
          <div className='relative flex items-center justify-center p-[10%] lg:w-1/2'>
            <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
              <img src='/images/gradient_light.jpg' alt='gradient' className='h-full w-full' />
            </picture>

            <div className='w-full max-w-[25.625rem] text-center'>
              <h1 className='text-jacarta-700 font-display mb-6 text-4xl dark:text-white'>
                Ingresa
              </h1>
              <p className='dark:text-jacarta-300 mb-10 text-lg leading-normal'>
                Todas tus fotos de perfil cuando quieras y en donde quieras.
              </p>

              <button
                onClick={handleGoogleLogin}
                className='dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent'
              >
                <img
                  src='/images/icons/google_logo.svg'
                  className='mr-2.5 inline-block h-6 w-6'
                  alt=''
                />
                <span>Continuar con Google</span>
              </button>
              {/* simple smooth separator */}
              <div className='relative mb-4'>
                <div className='absolute inset-0 flex items-center'>
                  <div className='w-full border-t border-jacarta-100 dark:border-jacarta-600'></div>
                </div>
                <div className='relative flex justify-center'>
                  <span className='px-2 mx-2 dark:bg-transparent bg-white font-semibold'>o</span>
                </div>
              </div>
              {/* inputs of email and password for login */}
              <form onSubmit={(e) => handleSubmit((data) => handleEmailLogin(data))(e)}>
                <div className='mb-4'>
                  <input
                    {...register('email', { required: true, pattern: /\S+@\S+\.\S+/ })}
                    onChange={handleEmailChange}
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Correo electrónico'
                    className='dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:text-white dark:hover:border-transparent text-jacarta-700 border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent w-full rounded-full'
                  />
                  {errors.email && <p className='mt-2 text-red'>Escribe un correo válido.</p>}
                  {/* <input
                    {...register('password', { required: true, minLength: 8 })}
                    value={password}
                    onChange={handlePasswordChange}
                    type='password'
                    name='password'
                    id='password'
                    placeholder='Contraseña'
                    className='dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:text-white dark:hover:border-transparent text-jacarta-700 border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent text-black w-full rounded-full mt-4'
                  />
                 {errors.password && <p className='mt-2 text-red'>Debe contener al menos 8 dígitos.</p>} */}
                  <button
                    type='submit'
                    className='dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:text-white hover:border-transparent text-black dark:text-white dark:hover:border-transparent mt-4'
                  >
                    Acceder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end login --> */}
    </div>
  );
}
