import {
  collection,
  doc,
  getDoc,
  query,
  updateDoc,
  where,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { DatabaseUser } from '../models/User';

export default function userService() {
  async function createDatabaseUser(databaseUser: DatabaseUser): Promise<DatabaseUser> {
    try {
      await setDoc(doc(db, 'user', databaseUser.id), databaseUser);
      console.log('user creado', databaseUser);
      return databaseUser;
    } catch (e) {
      console.error('Error adding document: ', e);
      return null;
    }
  }

  async function updateDatabaseUser(
    userId: string,
    databaseUser: Partial<DatabaseUser>
  ): Promise<boolean> {
    try {
      const userRef = doc(db, 'user', userId);
      await updateDoc(userRef, { ...databaseUser });
      console.log('User updated ');
      return true;
    } catch (e) {
      console.error('Error updating document: ', e);
      return false;
    }
  }

  // TODO: use onSnapshot(collection(db, "user", id) to update user in context
  async function getDatabaseUser(id: string): Promise<DatabaseUser> {
    const userRef = doc(db, 'user', id);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      console.log('Document data:', userSnap.data());
      return userSnap.data() as DatabaseUser;
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
      return null;
    }
  }

  async function getDatabaseUserByEmail(email: string): Promise<DatabaseUser> {
    const myQuery = query(collection(db, 'user'), where('email', '==', email));
    const querySnapshot = await getDocs(myQuery);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data() as DatabaseUser;
    } else {
      return null;
    }
  }

  return {
    createDatabaseUser,
    updateDatabaseUser,
    getDatabaseUser,
    getDatabaseUserByEmail,
  };
}
